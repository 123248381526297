import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import LayoutDarkMarkdown from '../components/layout-dark-markdown';
import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FaSolarPanel, FaHandHoldingUsd, FaAward } from 'react-icons/fa/index.esm.js';
import HomeSelector from '../components/home-selector';
import { Feature, FeatureIcon, FeatureSummary } from '../components/feature';
export const pageQuery = graphql`
  query {
    josh01: file(relativePath: { eq: "josh01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = LayoutDarkMarkdown;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Fluid Solar" mdxType="SEO" />
    <div className="escape-container bg-primary h-56 text-gray-200 flex flex-col justify-center" style={{
      height: 500
    }}>
  <div className="mb-24" style={{
        marginLeft: '10%',
        maxWidth: '50rem'
      }}>
    <h1 className="mt-0 text-5xl leading-tight">
      The future of <br />
      <strong className="text-white">affordable, energy-efficient housing</strong>
      <br />
      and commercial spaces
    </h1>
    <p className="text-2xl">For a low impact on the planet, your property and your budget</p>
  </div>
    </div>
    <p>{`At Fluid Solar, we combine ingenuity, world-class engineering and a passion for change to
create affordable products with a positive impact. Our designs are made to benefit
individuals, communities and the environment by providing a genuine and accessible
solution to the rising cost of housing and the difficulties of sustainable energy creation
and storage. We aim to create a better choice on behalf of families and the earth, no
matter your building requirements.`}</p>
    <Feature mdxType="Feature">
  <FeatureIcon mdxType="FeatureIcon">
    <FaSolarPanel className="h-auto text-primary-dark w-full" style={{
          maxWidth: '6rem'
        }} mdxType="FaSolarPanel" />
  </FeatureIcon>
  <FeatureSummary mdxType="FeatureSummary">
    <h2 className="text-lg text-black">Sustainable.</h2>
    <p>
      Our homes function with maximum energy efficiency, thermal control, and enable totally
      off-grid living where desired.
    </p>
  </FeatureSummary>
    </Feature>
    <Feature mdxType="Feature">
  <FeatureIcon mdxType="FeatureIcon">
    <FaAward className="h-auto text-primary-dark w-full" style={{
          maxWidth: '6rem'
        }} mdxType="FaAward" />
  </FeatureIcon>
  <FeatureSummary mdxType="FeatureSummary">
    <h2 className="text-lg text-black">High-quality.</h2>
    <p>
      Our versatile designs are available in a range of finishes for customisability and the
      feel of luxury.
    </p>
  </FeatureSummary>
    </Feature>
    <Feature mdxType="Feature">
  <FeatureIcon mdxType="FeatureIcon">
    <FaHandHoldingUsd className="h-auto text-primary-dark w-full" style={{
          maxWidth: '6rem'
        }} mdxType="FaHandHoldingUsd" />
  </FeatureIcon>
  <FeatureSummary mdxType="FeatureSummary">
    <h2 className="text-lg text-black">Affordable.</h2>
    <p>
      Factory fitted with 72-hour construction times, minimum onsite trades, and
      transportable anywhere.
    </p>
  </FeatureSummary>
    </Feature>
    {
      /* By combining our unique solar thermal energy technology with environmentally-conscious
      design and rapid construction, we aim to minimise our environmental impact and provide
      truly accessible and affordable products. Learn more about our [company and technology](https://fluidsolarthermal.com/), or start browsing
      below to find the product which best suits your needs. */
    }
    <h1 {...{
      "id": "our-builds"
    }}>{`Our Builds`}</h1>
    <p>{`Our modular homes meet all briefs in residential construction, including houses of any size, holiday homes, additions to existing properties, and emergency Refuges designed to withstand bushfire impact. Modular construction means that the components for these projects are built in an SA factory, under the supervision of our experienced team of engineers and manufacturers. The modules are transportable by forklift, tractor or a standard-width trailer, and are clamped securely together on a prepared site in a few hours or days. We can deliver and assemble a complete four bedroom home in less than four days on a prepared site, whereas it would take around six to twelve months using conventional on site building techniques. Our methods mean that rural and remote locations, sloping sites and residential areas with small access points are all still feasible build areas.`}</p>
    <div className="flex">
  <Link to="/modular-homes" className="mr-3 text-xl outline-button move-up" mdxType="Link">
    Your Home
  </Link>
    <Link to="/home-additions" className="mr-3 text-xl outline-button move-up" mdxType="Link">
    Your Addition
  </Link>
    <Link to="/emergency-solutions" className="mr-3 text-xl outline-button move-up" mdxType="Link">
    Emergency Solutions
  </Link>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/439b11d09fcbe8fc5c2fcb850bdd1895/94141/MAISON_2_vAR_1_no%20porch.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.625%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAcaiSQB//8QAGBABAQEBAQAAAAAAAAAAAAAAAgEDERP/2gAIAQEAAQUCNonpzN6JX//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAXEQEBAQEAAAAAAAAAAAAAAAABAAIS/9oACAECAQE/ATTdN//EABgQAQADAQAAAAAAAAAAAAAAAAEAESFR/9oACAEBAAY/AsWDSvJhU//EABgQAAMBAQAAAAAAAAAAAAAAAAABESFx/9oACAEBAAE/IeRsgnb6dbGUsh//2gAMAwEAAgADAAAAEIf/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQMBAT8Qw1SI/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EIm2f//EABsQAQEAAgMBAAAAAAAAAAAAAAERAFEhQWGR/9oACAEBAAE/EB042NV2Y/OoKAXhQPOt45dOBX7n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "MAISON 2 vAR 1 no porch",
            "title": "MAISON 2 vAR 1 no porch",
            "src": "/static/439b11d09fcbe8fc5c2fcb850bdd1895/7ef33/MAISON_2_vAR_1_no%20porch.jpg",
            "srcSet": ["/static/439b11d09fcbe8fc5c2fcb850bdd1895/c635c/MAISON_2_vAR_1_no%20porch.jpg 500w", "/static/439b11d09fcbe8fc5c2fcb850bdd1895/7e3f6/MAISON_2_vAR_1_no%20porch.jpg 1000w", "/static/439b11d09fcbe8fc5c2fcb850bdd1895/7ef33/MAISON_2_vAR_1_no%20porch.jpg 2000w", "/static/439b11d09fcbe8fc5c2fcb850bdd1895/2d501/MAISON_2_vAR_1_no%20porch.jpg 3000w", "/static/439b11d09fcbe8fc5c2fcb850bdd1895/94141/MAISON_2_vAR_1_no%20porch.jpg 3200w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/920cb9679af9dec00df07569b2af103d/b89a9/EX_12_sq_Ext__0000.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHbKYxooX//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIDEBESI//aAAgBAQABBQL2U1dYdWEmbeP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARIT/9oACAECAQE/AdF5f//EABoQAAICAwAAAAAAAAAAAAAAAAABAjEgM6H/2gAIAQEABj8CTfKNZUcP/8QAHRAAAgAHAQAAAAAAAAAAAAAAAAEQESExQVGR8P/aAAgBAQABPyGaRpqAPtYxWE7J0//aAAwDAQACAAMAAAAQg+//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QiIj/xAAWEQEBAQAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QUbL/xAAdEAACAQQDAAAAAAAAAAAAAAABEQAhMVGBQWGR/9oACAEBAAE/EHNvhKd8x1KcFEOA9hxQyWpcD1SF59Gf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EX 12 sq Ext  0000",
            "title": "EX 12 sq Ext  0000",
            "src": "/static/920cb9679af9dec00df07569b2af103d/b89a9/EX_12_sq_Ext__0000.jpg",
            "srcSet": ["/static/920cb9679af9dec00df07569b2af103d/c635c/EX_12_sq_Ext__0000.jpg 500w", "/static/920cb9679af9dec00df07569b2af103d/7e3f6/EX_12_sq_Ext__0000.jpg 1000w", "/static/920cb9679af9dec00df07569b2af103d/b89a9/EX_12_sq_Ext__0000.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1 {...{
      "id": "our-technology"
    }}>{`Our Technology`}</h1>
    <p>{`Fluid Solar living spaces cut typical energy costs for occupants by as much as 90%. “Thin grid” and off-grid options are available for rural and remote locations or city-based low energy living. You can order a home with or without our patented solar thermal energy collection systems—the new and better way to do solar power—but all of our builds incorporate passive sustainable design principles to keep you comfortable and keep energy bills at a minimum by regulating building temperature.`}</p>
    <p>
  <Link to="/our-tech" className="text-xl outline-button move-up" mdxType="Link">
    Our Technology
  </Link>
    </p>
    <h1 {...{
      "id": "past-projects"
    }}>{`Past Projects`}</h1>
    <p>{`At Fluid Solar, our R&D team are at the forefront of sustainable commercial development, having completed the world’s first off-grid, solar-thermal powered four storey office building—Fluid Solar House—in Elizabeth Vale, South Australia in 2017. The building’s heating, cooling and lighting system has now operated for more than 2 years completely off the grid, powered by rooftop solar thermal and solar PV panels. More recently, our modular homes have been installed as cabins at the Gawler Caravan Park, and we are in the process of completing a large, multi-residential housing development. Though our focus is now on bringing our products to individuals in a residential context, if you wish to construct a multi-residential development, small to medium sized office building, educational institution, retail store, or anything other occupied space, our team of designers and engineers can provide a fast, sustainable and on-budget solution.`}</p>
    <p>
  <Link to="/commercial-inquiries" className="mr-3 text-xl outline-button move-up" mdxType="Link">
    Commercial Inquiries
  </Link>
  <Link to="/projects" className="text-xl outline-button move-up" mdxType="Link">
    Projects
  </Link>
    </p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b915ee99e4a091d3698c5fde81e448eb/2d501/01%20From%20roundabout.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAFNLskZ7L//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQMSEBET/9oACAEBAAEFAukZaIvEiqNLH//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQADAAAAAAAAAAAAAAAAAAAAARP/2gAIAQIBAT8BqyzP/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAExEBH/2gAIAQEABj8CvMqIiZ//xAAdEAACAgEFAAAAAAAAAAAAAAAAEQEhEGGBkcHR/9oACAEBAAE/IWuamkQpPc9zBb1Gk4x//9oADAMBAAIAAwAAABB3P//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EJQ//8QAFREBAQAAAAAAAAAAAAAAAAAAAGH/2gAIAQIBAT8Qkg//xAAbEAEAAwEBAQEAAAAAAAAAAAABABExIUGBsf/aAAgBAQABPxBTUepG8u6vkLZ2h057hKgOXos4fwQHB+JQYE//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "01 From roundabout",
            "title": "01 From roundabout",
            "src": "/static/b915ee99e4a091d3698c5fde81e448eb/7ef33/01%20From%20roundabout.jpg",
            "srcSet": ["/static/b915ee99e4a091d3698c5fde81e448eb/c635c/01%20From%20roundabout.jpg 500w", "/static/b915ee99e4a091d3698c5fde81e448eb/7e3f6/01%20From%20roundabout.jpg 1000w", "/static/b915ee99e4a091d3698c5fde81e448eb/7ef33/01%20From%20roundabout.jpg 2000w", "/static/b915ee99e4a091d3698c5fde81e448eb/2d501/01%20From%20roundabout.jpg 3000w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4434dcbd5812572fa019e26c7c04433c/7e975/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.160000000000004%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABXjuGiK//xAAYEAADAQEAAAAAAAAAAAAAAAABAhEAEP/aAAgBAQABBQK5mALOaOTf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABYRAAMAAAAAAAAAAAAAAAAAAAABEv/aAAgBAgEBPwG2Uz//xAAXEAADAQAAAAAAAAAAAAAAAAAAASEg/9oACAEBAAY/AiUjWP/EABoQAAIDAQEAAAAAAAAAAAAAAAABESFBUTH/2gAIAQEAAT8h9xRZEOmBBFKrSFLpCTiP/9oADAMBAAIAAwAAABB4D//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EIQ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxAD/8QAHBABAAICAwEAAAAAAAAAAAAAAQAhETFBUXHB/9oACAEBAAE/EEuIBVsTACpw5fly0qTnU1heDXsMdR1j2ByG7qf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Light avenue",
            "title": "Light avenue",
            "src": "/static/4434dcbd5812572fa019e26c7c04433c/7ef33/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg",
            "srcSet": ["/static/4434dcbd5812572fa019e26c7c04433c/c635c/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 500w", "/static/4434dcbd5812572fa019e26c7c04433c/7e3f6/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 1000w", "/static/4434dcbd5812572fa019e26c7c04433c/7ef33/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 2000w", "/static/4434dcbd5812572fa019e26c7c04433c/7e975/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg 2500w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "2000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b04edf45acbbf25ab83eead37506c6e8/2972e/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdCOiMUDBP/EABkQAQEAAwEAAAAAAAAAAAAAAAIBAAMRBP/aAAgBAQABBQK+jhqb1a+soS4jZBGT/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAbEAABBQEBAAAAAAAAAAAAAAAAAQIRMVESIf/aAAgBAQAGPwKpcdK7zCSjUIah/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERITFBUWGR/9oACAEBAAE/IcQmiMDZ2yUU3mLFQh/gyPFKZtn/2gAMAwEAAgADAAAAEAvf/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EA2P/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EFyP/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBYZH/2gAIAQEAAT8QDQhYb9rW+R7l1dBq/OMl5h0a6IFHQUI6Eqg58od8Zej1QVuf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gawler group",
            "title": "gawler group",
            "src": "/static/b04edf45acbbf25ab83eead37506c6e8/7ef33/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg",
            "srcSet": ["/static/b04edf45acbbf25ab83eead37506c6e8/c635c/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 500w", "/static/b04edf45acbbf25ab83eead37506c6e8/7e3f6/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 1000w", "/static/b04edf45acbbf25ab83eead37506c6e8/7ef33/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 2000w", "/static/b04edf45acbbf25ab83eead37506c6e8/2972e/Gawler%20Group%206%20cottages%20IMG_20180221_144701_941.jpg 2592w"],
            "sizes": "(max-width: 2000px) 100vw, 2000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div className="escape-container mt-32">
  <HomeSelector mdxType="HomeSelector" />
    </div>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      